import React, { useContext, useState, useEffect, useCallback } from 'react';
import styles from './styles.module.css';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { AlertMessagesContext } from 'react-alert-messages';
import { useLocation } from 'react-router-dom';
import { Grid, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import { Button, TextField, Menu, MenuItem, Divider, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ItemService from '../../../services/ItemService';
import CustomerService from '../../../services/CustomerService';
import SaleService from '../../../services/SaleService';
import Loader from '../../utils/Loading';
import SearchIcon from '@material-ui/icons/Search';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { roundOff } from '../../../helpers/NumberFormatHelper';

function AddSales() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const [searchText, setSearchText] = useState('');
  const [saleItems, setSaleItems] = useState([]);
  const [items, setItems] = useState([]);
  const [billNo, setBillNo] = useState('');
  const [itemName, setItemName] = useState('');
  const [rate, setRate] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [tax, setTax] = useState(0);
  const [cess, setCess] = useState(0);
  const [item, setItem] = useState(null);
  const [totalAmount, setTotalAmount] = useState('');
  const [editData, setEditData] = useState({});
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [billDate, setBillDate] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);
  const [orders, setOrders] = useState([]);
  const [saleTotals, setSaleTotals] = useState({
    payable: 0,
    tax: 0,
    cess: 0,
  });
  const [discount, setDiscount] = useState(0);
  const [saleId, setSaleId] = useState(id ?? null);

  const open = Boolean(anchorEl);

  const dltItem = (item) => {
    setSaleItems(saleItems.filter(({ item_id, rate }) => item_id !== item.item_id || rate !== item.rate));
  };

  const resetItemFields = () => {
    setItemName('');
    setRate('');
    setQuantity('');
    setTax('');
    setCess('');
    setTotalAmount('');
    setItem(null);
  };

  const clearSaleHandler = () => {
    setBillDate(new Date());
    setBillNo('');
    setSelectedCustomer(null);
    resetItemFields();
    setSaleItems([]);
    setEditData({});
  };

  const addItem = () => {
    const data = {
      item_id: item.id,
      rate,
      quantity,
      tax,
      cess,
      payable: totalAmount,
      name: itemName,
      item,
    };
    setSaleItems([...saleItems, data]);
    resetItemFields();
  };

  const getItems = async () => {
    const items = await ItemService.getItems();
    setItems(items);
  };

  const getCustomers = async () => {
    const resp = await CustomerService.getCustomers();
    setCustomers(resp);
  };

  const fetchInitialData = async () => {
    setLoadingIndicator(true);
    await Promise.all([getItems(), getCustomers()]);
    setLoadingIndicator(false);
  };

  const handleEditSale = async () => {
    setLoadingIndicator(true);
    try {
      const data = {
        ...editData,
        time: new Date(billDate),
        payable: saleTotals?.payable,
        tax: saleTotals?.tax,
        cess: saleTotals?.cess,
        customer_id: selectedCustomer,
        bill_no: billNo,
        items: saleItems,
      };
      
      await SaleService.updateSale(saleId, data);
      postAlertMessage({
        text: 'Sale updated successfully',
        type: 'success',
      });
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const handleAddSale = async () => {
    setLoadingIndicator(true);
    try {
      const data = {
        ...editData,
        time: new Date(billDate),
        payable: saleTotals?.payable,
        tax: saleTotals?.tax,
        cess: saleTotals?.cess,
        customer_id: selectedCustomer,
        bill_no: billNo,
        items: saleItems,
      };
      await SaleService.createSale(data);
      postAlertMessage({
        text: 'Sale added successfully',
        type: 'success',
      });
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const handleSearchClick = async (event) => {
    if (searchText.length === 0) {
      postAlertMessage({ text: 'Enter search text', type: 'failed' });
      setAnchorEl(null);
      return;
    }
    setLoadingIndicator(true);
    try {
      const searchAnchor = event.currentTarget;
      const resp = await SaleService.getSales({ search: searchText });
      if (resp.length === 0) {
        postAlertMessage({ text: 'No sales found', type: 'failed' });
        setAnchorEl(null);
        setLoadingIndicator(false);
        return;
      }
      setAnchorEl(searchAnchor);
      setOrders(resp);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const handleSearchClose = () => {
    setAnchorEl(null);
  };

  const handleSelectSaleClick = (sale) => {
    setEditData(sale);
    setBillNo(sale.bill_no);
    setSaleId(sale.id);
    setBillDate(new Date(sale.time));
    setSaleItems(
      sale.items.map((si) => {
        return { ...si, item_id: si.item.id, name: si?.item?.name };
      })
    );
    setDiscount(sale?.discount);
    setSelectedCustomer(sale?.customer?.id);
    setAnchorEl(null);
  };

  const selectItemHandler = (item) => {
    setItem(item || '');
    setItemName(item.name || '');
    setRate(item.price);
    setQuantity(1);
    let itemTax = getTax(item.price, quantity, item.tax);
    let itemCess = getCess(item.price, quantity, item.cess);
    setTax(itemTax);
    setCess(itemCess);
    setTotalAmount(getItemRate(item.price) + itemTax + itemCess);
  };

  const getRate = (val, qty = quantity, iTax = item.tax) => {
    return roundOff(Number(val) / (qty * (1 + iTax / 100)));
  };

  const getItemRate = useCallback(
    (iRate = rate, iQty = quantity) => {
      return roundOff(Number(iRate) * iQty);
    },
    [rate, quantity]
  );

  const getTax = useCallback(
    (iRate = rate, iQty = quantity, iTax = item?.tax ?? 0) => {
      return roundOff((getItemRate(iRate, iQty) * iTax) / 100);
    },
    [item?.tax, getItemRate, quantity, rate]
  );

  const getCess = useCallback(
    (iRate = rate, iQty = quantity, iCess = item?.cess ?? 0) => {
      return roundOff((getItemRate(Number(iRate), iQty) * iCess) / 100);
    },
    [item?.cess, getItemRate, quantity, rate]
  );

  const handleChangePayable = (val) => {
    if (quantity === 0 || item === null) return;
    setTotalAmount(val);
    let iRate = getRate(val);
    let itemTax = getTax(iRate);
    let itemCess = getCess(iRate);
    setTax(itemTax);
    setCess(itemCess);
    setRate(iRate);
  };

  const handleChangeRate = (val) => {
    if (quantity === 0 || item === null) return;
    let itemTax = getTax(val);
    let itemCess = getCess(val);
    setTax(itemTax);
    setCess(itemCess);
    setRate(val);
    setTotalAmount(roundOff(getItemRate(val) + itemTax + itemCess));
  };

  const handleChangeQty = (val) => {
    if (item === null) return;
    let itemTax = getTax(rate, val);
    let itemCess = getCess(rate, val);
    setTax(itemTax);
    setCess(itemCess);
    setQuantity(val);
    setTotalAmount(roundOff(getItemRate(rate, val) + itemTax + itemCess));
  };

  const handleFieldChange = (id, field, value) => {
    setSaleItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id !== id) return item;

        const updatedItem = { ...item, [field]: Number(value) };
        const { quantity = 0, item: itemDetails } = updatedItem;

        if (field === 'payable' && quantity > 0) {
          updatedItem.rate = getRate(updatedItem.payable, quantity, itemDetails?.tax);
        }

        updatedItem.tax = getTax(updatedItem.rate, quantity, itemDetails?.tax);
        updatedItem.cess = getCess(updatedItem.rate, quantity, itemDetails?.cess);
        if (field !== 'payable')
          updatedItem.payable = getItemRate(updatedItem.rate, quantity) + updatedItem.tax + updatedItem.cess;

        return updatedItem;
      })
    );
  };

  useEffect(() => {
    const totals = saleItems.reduce(
      (acc, item) => {
        acc.payable += item.payable || 0;
        acc.tax += item.tax || 0;
        acc.cess += item.cess || 0;
        return acc;
      },
      { payable: 0, tax: 0, cess: 0 }
    );

    setSaleTotals(totals);
  }, [saleItems]);

  useEffect(() => {
    fetchInitialData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!id) {
      return;
    }

    const getSaleData = async () => {
      const resp = await SaleService.getSale(id);
      setEditData(resp);
      setBillDate(new Date(resp.time));
      setSaleItems(
        resp.items.map((si) => {
          return { ...si, item_id: si.item.id, name: si?.item?.name };
        })
      );
      setSelectedCustomer(resp?.customer?.id);
    };
    getSaleData();
  }, [id]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Sales <span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>{`${editData.id ? 'Edit' : 'New'} Sale`}</h2>
          </div>
          <div className={styles.searchSec}>
            <input
              type="text"
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSearchClick(event);
                }
              }}
              className={styles.searchInput}
              placeholder="Search sale"
            />
            <SearchIcon
              className={styles.searchIcon}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleSearchClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              style={{ minWidth: 200 }}
              onClose={handleSearchClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {orders.map((sale) => (
                <>
                  <MenuItem className={styles.menuList} onClick={() => handleSelectSaleClick(sale)}>{`${sale.bill_no}${
                    sale.customer ? ` - ${sale.customer.name}` : ''
                  }`}</MenuItem>
                  <Divider />
                </>
              ))}
            </Menu>
          </div>
        </div>

        <Grid container justify="flex-end" alignItems="center" pt={3} rowSpacing={2}>
          <Grid item xs={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Bill no</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField
              fullWidth
              id="demo-select-small"
              value={billNo}
              label="Bill no"
              variant="outlined"
              size="small"
              onChange={(e) => {
                setBillNo(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Customer</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Autocomplete
              value={customers.find((c) => c.id === selectedCustomer) || null}
              onChange={(event, newValue) => {
                if (!newValue) {
                  setSelectedCustomer('');
                } else {
                  setSelectedCustomer(newValue.id || '');
                }
              }}
              options={customers}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Customer"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
              renderOption={(option) => (
                <MenuItem
                  key={option.id}
                  style={{
                    paddingTop: '1px',
                    paddingBottom: '1px',
                  }}
                >
                  {option.name}
                </MenuItem>
              )}
              ListboxProps={{
                style: { maxHeight: '200px' },
              }}
              freeSolo={false}
            />
          </Grid>
          <Grid item xs={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Discount</h3>
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField
              fullWidth
              id="demo-select-small"
              value={discount}
              disabled
              label="Discount"
              variant="outlined"
              size="small"
              onChange={(e) => {}}
            />
          </Grid>
          <Grid item xs={2} justifyContent="flex-end" className={styles.inputLabelContainer}>
            <h3 className={styles.inputLabel}>Bill Date</h3>
          </Grid>
          <Grid item xs={4} sm={4} display="flex">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableFuture
                format="dd/MM/yyyy"
                id="date-picker-from"
                className={styles.dateBox}
                value={billDate}
                onChange={(date) => {
                  setBillDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'Change date',
                }}
                variant="inline"
                inputVariant="outlined"
                size="small"
              />
              <KeyboardTimePicker
                disableFuture
                id="time-picker-from"
                className={styles.dateBox}
                value={billDate}
                onChange={(date) => {
                  setBillDate(date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'Change Time',
                }}
                variant="inline"
                inputVariant="outlined"
                size="small"
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </div>

      <TableContainer style={{ marginTop: 4 }} component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell className={styles.nameColumn}>Item Name</TableCell>
              <TableCell className={styles.columnCell}>Rate</TableCell>
              <TableCell className={styles.columnCell}>Quantity</TableCell>
              <TableCell className={styles.columnCell}>Total Tax</TableCell>
              <TableCell className={styles.columnCell}>Total</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {saleItems &&
              saleItems.map((saleItem) => (
                <TableRow key={saleItem.id}>
                  <TableCell>{saleItem.name}</TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      type="number"
                      size="small"
                      value={saleItem.rate === 0 ? '' : saleItem.rate}
                      onChange={(e) => handleFieldChange(saleItem.id, 'rate', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      value={saleItem.quantity === 0 ? '' : saleItem.quantity}
                      onChange={(e) => handleFieldChange(saleItem.id, 'quantity', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      disabled
                      value={Number(saleItem.tax) + Number(saleItem.cess) || 0}
                      onChange={(e) => handleFieldChange(saleItem.id, 'tax', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="number"
                      value={saleItem.payable}
                      onChange={(e) => handleFieldChange(saleItem.id, 'payable', e.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => dltItem(saleItem)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            <TableRow key="input row">
              <TableCell>
                <Autocomplete
                  value={item}
                  onChange={(event, newItem) => {
                    selectItemHandler(newItem);
                  }}
                  disableClearable
                  inputValue={itemName}
                  onInputChange={(e, value) => {
                    setItemName(value);
                  }}
                  getOptionLabel={(option) => `${option?.name}`}
                  options={items}
                  renderInput={(params) => (
                    <TextField {...params} label="Name" variant="outlined" size="small" fullWidth />
                  )}
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Rate"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={rate}
                  onChange={(e) => handleChangeRate(e.target.value)}
                  type="number"
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Quantity"
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={quantity}
                  onChange={(e) => handleChangeQty(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Tax"
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled
                  value={Number(tax) + Number(cess) || 0}
                  type="number"
                  onChange={(e) => {}}
                />
              </TableCell>
              <TableCell>
                <TextField
                  label="Payable"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={totalAmount}
                  type="number"
                  onChange={(e) => handleChangePayable(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <IconButton disabled={itemName === '' || rate === ''} onClick={addItem}>
                  <LibraryAddIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.nameColumn}>Total</TableCell>
              <TableCell className={styles.columnCell}></TableCell>
              <TableCell className={styles.columnCell}></TableCell>
              <TableCell className={styles.columnCell}>{saleTotals?.tax + saleTotals?.cess + tax + cess}</TableCell>
              <TableCell className={styles.columnCell}>{saleTotals?.payable + totalAmount}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container className={styles.submitSection} justify="flex-end">
        <Button variant="contained" color="secondary" className={styles.closeBtn} onClick={clearSaleHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: '#00a65a' }}
          disabled={billDate === '' || loadingIndicator || !editData.id}
          onClick={editData.id ? handleEditSale : handleAddSale}
        >
          {editData.id ? 'Update Sale' : 'Save Sale'}
        </Button>
      </Grid>
    </div>
  );
}

export default withConsoleBase(AddSales);
