import BackendService from './BackendService';

async function getShift(id) {
  return BackendService.getData(`/shifts/${id}/`);
}
async function getShifts(params) {
  return BackendService.getData('/shifts/', {}, params);
}
async function getShiftReport(id) {
  return BackendService.getData(`/shifts/${id}/report/payment-mode/`);
}
async function getShiftOrders(id) {
  return BackendService.getData(`/shifts/${id}/orders/`);
}

const ShiftService = {
  getShift,
  getShifts,
  getShiftReport,
  getShiftOrders,
};

export default ShiftService;
